<script>
import DepartmentNew from './DepartmentNew'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'DepartmentEdit',
  extends: DepartmentNew,
  methods: {
    getDepartment () {
      this.$store.dispatch('department/fetchOne', this.$route.params.id)
        .then(() => {
          this.department = this.$store.getters['department/detail']
          this.setDepartmentManagers()
        })
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.$store.dispatch('department/update', this.department)
        .then(() => {
          this.department = this.$store.getters['department/detail']
          if (this.$store.getters['department/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['department/error'])
          }
        })
        .catch(error => console.log(error))
    },
    setDepartmentManagers () {
      this.departmentManagers = this.managers.filter(function (departmentManager) {
        return undefined !== this.department.managers.find(function (managerId) {
          return managerId === departmentManager.id
        })
      }, this)
    }
  },
  created () {
    this.getDepartment()
  }
}
</script>
